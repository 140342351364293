import React, { useCallback, useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Box,
  styled,
  ButtonBase,
  CircularProgress,
  Link,
} from '@mui/material';
import { useSearchParams, NavLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import OTPInput from 'react-otp-input';
import moment from 'moment';

import Center from '../../../components/ui/Center';

import notify from '../../../configs/notify';
import colors from 'src/configs/colors';
import {
  apiCheckPasswordOTP,
  apiRequestEmailOTP,
  apiRequestPasswordOTP,
  apiVerifyEmail,
  apiVerifyMfaCode,
} from 'src/apis/auth';
import { setAuth } from 'src/redux/auth/slice';

const OtpItemSeparator = styled('div')`
  width: 20px;
`;

const OtpInputCell = styled(`input`)`
  font-family: 'Montserrat';
  font-size: 20px;
  border: none;
  outline: none;
  border-bottom: 2px solid ${colors['gray.600']};
  padding: 8px 12px 5px 12px;
  color: ${colors.text};

  &::placeholder {
    color: ${colors['gray.300']};
  }
  &:focus::placeholder {
    color: transparent;
  }
`;

const GoldVerifyOTPPage = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState('');
  const [startedAt, setStartedAt] = useState();
  const [seconds, setSeconds] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const handleStartCountdown = useCallback(() => {
    setStartedAt(moment());
  }, [setStartedAt]);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    const action = params.get('action');
    const email = params.get('email');
    const phone = params.get('phone');

    if (action === 'register' || action === 'verify') {
      apiVerifyEmail({
        email: email,
        code: otp,
      })
        .then((result) => {
          if (result) {
            notify({
              message: t("You've verified your email successfully"),
              type: 'success',
            });
            navigate('/setup-account', {
              replace: true,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (action === 'password-recovery') {
      apiCheckPasswordOTP({
        email: email,
        code: otp,
        type: 'reset-password',
      })
        .then((result) => {
          if (result) {
            notify({
              message: t("You've verified your verification code successfully"),
              type: 'success',
            });
            navigate(`/auth/reset-password?email=${email}&code=${otp}`, {
              replace: true,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (action === '2fa-email' || action === '2fa-sms') {
      apiVerifyMfaCode({
        target: action === '2fa-email' ? email : phone,
        code: otp,
        method: action === '2fa-email' ? 'email' : 'sms',
      })
        .then((result) => {
          if (result && result.data) {
            notify({
              message: t('You have signed in successfully'),
              type: 'success',
            });
            dispatch(
              setAuth({
                ...result.data,
              }),
            );
            if (result.data.isSetupCompleted) {
              navigate('/dashboard', {
                replace: true,
              });
            } else {
              navigate('/setup-account', {
                replace: true,
              });
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [otp, params]);

  const handleResend = useCallback(() => {
    if (sending) {
      return;
    }

    setSending(true);
    if (
      params.get('action') === 'register' ||
      params.get('action') === 'verify'
    ) {
      apiRequestEmailOTP({
        email: params.get('email'),
      })
        .then((result) => {
          if (result) {
            notify({
              message: t('We’ve sent a verification email to email', {
                email: params.get('email'),
              }),
              type: 'success',
            });
          }
        })
        .finally(() => {
          setSending(false);
          handleStartCountdown();
        });
    }
    if (params.get('action') === 'password-recovery') {
      apiRequestPasswordOTP({
        email: params.get('email'),
      })
        .then((result) => {
          if (result) {
            notify({
              message: t('We’ve sent a verification email to email', {
                email: params.get('email'),
              }),
              type: 'success',
            });
          }
        })
        .finally(() => {
          setSending(false);
          handleStartCountdown();
        });
    }
    if (params.get('action') === '2fa-email') {
      //
    }
    if (params.get('action') === '2fa-sms') {
      //
    }
  }, [sending, params]);

  useEffect(() => {
    handleStartCountdown();
  }, []);

  useEffect(() => {
    if (startedAt) {
      const handler = setInterval(() => {
        const d = moment().diff(startedAt, 'second');
        setSeconds(60 - d);

        if (d > 60) {
          clearInterval(handler);
        }
      }, 1000);

      return () => clearInterval(handler);
    }
  }, [startedAt]);

  useEffect(() => {
    if (
      !params.get('action') ||
      (!params.get('email') && !params.get('phone')) ||
      ![
        'register',
        'verify',
        'password-recovery',
        '2fa-email',
        '2fa-sms',
      ].includes(params.get('action'))
    ) {
      navigate('/auth/login', {
        replace: true,
      });
    }
  }, [params]);

  return (
    <Stack
      minHeight="100vh"
      py={3}
      px={6}
      boxSizing="border-box"
      justifyContent="center"
      alignItems="center"
    >
      <Box maxWidth={450}>
        <Typography variant="h3" weight={600} colorscheme="heading">
          {params.get('action') === '2fa-email' && 'Enter OTP Code'}
          {params.get('action') === '2fa-sms' && 'Enter OTP Code'}
          {params.get('action') === 'password-recovery' &&
            'Enter Verification Code'}
          {(params.get('action') === 'verify' ||
            params.get('action') === 'register') &&
            'Verify Email'}
        </Typography>
        <Typography fontSize={16} colorscheme="heading" lineHeight={1.5} mt={3}>
          {params.get('action') === '2fa-email' &&
            'A 6 digit OTP Code has been send to your registered email, please enter the code to login.'}
          {params.get('action') === '2fa-sms' &&
            'A 6 digit OTP Code has been send to your registered phone number, please enter the code to login.'}
          {params.get('action') === 'password-recovery' &&
            'A 6 digit verification code has been send to your registered email, please enter the code to change your password.'}
          {(params.get('action') === 'verify' ||
            params.get('action') === 'register') &&
            'A 6 digit verification code has been sent to the email you provided, please enter the code below.'}
        </Typography>
        <Center mt={4}>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => <OtpInputCell {...props} placeholder="0" />}
            renderSeparator={<OtpItemSeparator />}
          />
          {['verify', 'register', 'password-recovery'].includes(
            params.get('action'),
          ) ? (
            <ButtonBase
              sx={{ mt: 5, px: 1, py: 0.5 }}
              disabled={sending || seconds > 0}
              onClick={handleResend}
            >
              {sending ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CircularProgress size={14} />
                  <Typography fontSize={14} colorscheme="fonts.text">
                    {t('Sending verification email...')}
                  </Typography>
                </Stack>
              ) : (
                <Typography fontSize={14} colorscheme="primary">
                  {t('Resend Code')}&nbsp;&nbsp;
                  {seconds >= 0 && (
                    <Typography
                      colorscheme="gray.500"
                      display="inline"
                      component="span"
                    >
                      ({seconds}s)
                    </Typography>
                  )}
                </Typography>
              )}
            </ButtonBase>
          ) : null}
        </Center>
        <LoadingButton
          fullWidth
          sx={{ mt: 5 }}
          variant="contained"
          loading={loading}
          disabled={sending || otp.length !== 6}
          onClick={handleSubmit}
        >
          {params.get('action') === 'password-recovery'
            ? t('Continue')
            : t('Sign In')}
        </LoadingButton>
        {params.get('action') === 'password-recovery' && (
          <Typography fontSize={16} mt={3} display="block" textAlign="center">
            {t('Do you remember your password?')}{' '}
            <Link component={NavLink} to="/auth/login" display="inline" replace>
              {t('Sign In')}
            </Link>
          </Typography>
        )}
        {params.get('action') === '2fa-email' ||
        params.get('action') === '2fa-sms' ? (
          <Typography fontSize={16} mt={3} display="block" textAlign="center">
            Do you want to try another way?&nbsp;
            <Link component={NavLink} to="/auth/login" display="inline" replace>
              {t('Sign In')}
            </Link>
          </Typography>
        ) : null}
      </Box>
    </Stack>
  );
};

export default GoldVerifyOTPPage;
