import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';

import imgBackground1 from '../../assets/images/ship-over.jpg';
import imgBackground2 from '../../assets/images/godl-over.png';

const Background = styled('img')`
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const AuthLayout = () => {
  const location = useLocation();

  return (
    <Box width="100%" height="100vh">
      <Scrollbars>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            {location.pathname.includes('gold') ? (
              <Background
                src={imgBackground2}
                alt="Background"
                style={{
                  height: '100vh',
                  width: '100%',
                  objectFit: 'cover',
                  display: 'block',
                  userSelect: 'none',
                }}
              />
            ) : (
              <Background
                src={imgBackground1}
                alt="Background"
                style={{
                  height: '100vh',
                  width: '100%',
                  objectFit: 'cover',
                  display: 'block',
                  userSelect: 'none',
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Outlet />
          </Grid>
        </Grid>
      </Scrollbars>
    </Box>
  );
};

export default AuthLayout;
